const frontPage = () => {
    // Services bottom
    let serviceBottom = document.querySelector(".service__bottom");
    if (serviceBottom) {
        const serviceBottomTl = gsap.timeline({
            scrollTrigger: {
                trigger: serviceBottom,
                start: "0 70%",
            },
        });

        serviceBottomTl
            .from(".service__bottom--icon div", {
                y: 50,
                opacity: 0.5,
                duration: 0.5,
                stagger: 0.2,
            })
            .from(
                ".service__bottom--title div",
                {
                    y: 25,
                    duration: 0.75,
                    ease: "power1.out",
                    stagger: 0.2,
                },
                "<"
            )
            .from(
                ".service__bottom--content",
                {
                    y: 25,
                    opacity: 0,
                    duration: 1,
                    ease: "power1.out",
                    stagger: 0.2,
                },
                "<"
            );
    }

    var tl = gsap.timeline();
    tl.to("#transition", {
        opacity: 1,
        visibility: "visible",
        translateY: "100vh",
        ease: "power1.inOut",
        duration: 1.5,
    });

    tl.to("#transition", {
        opacity: 0,
        visibility: "hidden",
    });

    tl.set("#transition", { translateY: "-170vh" });
    tl.set("#transition", { opacity: 1 });


};

export default frontPage;
